<template>
  <p>message: {{ message }}</p>
</template>

<script>
// 在这里导入模块，而不是在 `store/index.js` 中
import msgLazyModule from '@/store/lazy/msg.js'

export default {
  asyncData ({ store, route }) {
    console.log('entry.server 触发 item.vue 的 asyncData()');
    store.registerModule('msg', msgLazyModule)
    // 触发 action 后，会返回 Promise
    return store.dispatch('msg/fetch_message', route.params.id)
  },
  computed: {
    // 从 store 的 state 对象中的获取 item。
    message () {
      return this.$store.state.msg.message
    }
  },
  // 重要信息：当多次访问路由时，
  // 避免在客户端重复注册模块。
  destroyed () {
    this.$store.unregisterModule('msg')
  },

}
</script>